var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.contributorsPage
    ? _c("div", { staticClass: "contributors-page-card column" }, [
        _c("div", { staticClass: "header row" }, [
          _c("span", [_vm._v(_vm._s(_vm.$t("contributorsPageBanner")))]),
          _c(
            "span",
            {
              staticClass: "close-icon",
              on: {
                click: function($event) {
                  _vm.goBack()
                }
              }
            },
            [_vm._v("×")]
          )
        ]),
        _c("div", { staticClass: "content column" }, [
          _c("span", {
            domProps: { innerHTML: _vm._s(_vm.contributorsPage.content) }
          }),
          !_vm.contributorsPage.content
            ? _c("span", { staticClass: "centered" }, [
                _vm._v(_vm._s(_vm.$t("noContentToDisplayMessage")))
              ])
            : _vm._e()
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }